import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { authUser } from '../src/api/userApi.js'
import Home from "./pages/Home";
import Product from "./pages/Product";
import Checkout from "./pages/CheckoutAll";
import NotFound from "./pages/ErrorPage";
import Completed from "./pages/Completed"
import Invalid from "./pages/Invalid"
import { USER_RESP_ID, CURRENT_USER_ID, USER_CHECKOUT } from "./consts.js"

export default class App extends React.Component {
    constructor(props){
        super(props)
        this.respId = new URLSearchParams(window.location.search).get('respId') || sessionStorage.getItem(USER_RESP_ID);
        if (sessionStorage.getItem(USER_RESP_ID) !== this.respId)
            sessionStorage.setItem(USER_RESP_ID, this.respId)
        const tempUser = JSON.parse(sessionStorage.getItem(CURRENT_USER_ID)) || {}
        this.state = {
            user: tempUser,
            realUser: tempUser !== undefined,
            completed: tempUser !== undefined ? tempUser.completed : false,
            auth: {}
        }
    }

    componentDidMount(){
        if (Object.keys(this.state.user).length === 0) {
            if (this.respId) {
                authUser(this.respId).then(res => {
                    if (res.isValid) {
                        sessionStorage.setItem(CURRENT_USER_ID, JSON.stringify(res.user))
                        this.setState({ ...this.state, user: res.user, completed: res.user.completed, auth: res.auth })
                    } else {
                        this.setState({ ...this.state, completed: false, realUser: false })
                    }
                })
            } else
                this.setState({ ...this.state, realUser: false })
        } else {
            this.setState({ ...this.state, realUser: true, completed: this.state.user.completed })
        }
    }

    componentWillUnmount(){
        sessionStorage.removeItem(CURRENT_USER_ID);
        sessionStorage.removeItem(USER_RESP_ID);
        sessionStorage.removeItem(USER_CHECKOUT);
    }

    render(){
        return (
            <Router>
                {!this.state.realUser && (<Routes>
                    <Route path="*" element={<Invalid />} />
                </Routes>)}
                {this.state.completed && (<Routes>
                    <Route path="*" element={<Completed />} />
                </Routes>)}
                {this.state.realUser && !this.state.completed && (<Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/product/:productId" element={<Product />} />
                    <Route exact path="/checkout" element={<Checkout changeUser={()=>{this.findUser()}}/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>)}
            </Router>);
    }
}